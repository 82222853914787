import {Grid, makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";
import {useEffect} from "react";

const useStyles = makeStyles(() => ({
    root: {
        width: 798,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 20,
        wordBreak: 'break-word'
    },
    heading: {
        marginRight: 5
    },
    header: {
        marginBottom: 30
    }
}));

function Upload() {
    const {t} = useTranslation('translation');
    const classes = useStyles();

    const cleanUp = (e) => {
        localStorage.clear();
        return null;
    }

    useEffect(()=>{
        window.addEventListener('beforeunload', cleanUp);

        return () => {
            window.removeEventListener('beforeunload', cleanUp);
        }
    },[])

    return (
        <Layout>
            <Grid
                className={classes.root}
                container
                direction="column"
                justifyContent="center"
                alignItems="center">

                <div className={classes.header}>
                    <Typography className={classes.heading} variant="h3" component="h3">
                        {t('THANK_YOU')}
                    </Typography>
                    <Typography className={classes.heading} variant="h6" component="h6">
                        {t('THANK_YOU_DESCRIPTION')}
                    </Typography>
                </div>
            </Grid>
        </Layout>
    );
}

export default Upload;
