import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";

function PageNotFound() {
    const { t } = useTranslation('translation');
    return (
        <Layout>
            <Typography color={'primary'} component="h1">
                {t('PAGE_NOT_FOUND')}
            </Typography>
        </Layout>

    );
}

export default PageNotFound;
