import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useQuery from '../utils/urlQuery';
import jwt_decode from 'jwt-decode';
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Grid,
    ListItem,
    ListItemIcon,
    makeStyles,
    Typography
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import FileServices from '../services/FileServices';
import {InfoRounded} from "@material-ui/icons";
import Stepper from "../ui/Stepper";
import api from "../utils/axios";
import {has} from "../utils/isArray";
import colors from "../utils/colors";
import Layout from "./layout/Layout";
import UploadHeader from "./upload/upload-header";
import UploadFolderItem from "./upload/upload-folder-item";
import UploadDocument from "./upload/upload-document";

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 720,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 40,
    },
    header: {
        marginRight: 5,
    },
    heading: {
        marginRight: 5,
    },
    content: {
        backgroundColor: colors.gainsboro,
        paddingTop: 30,
        paddingBottom: 30
    },
    documents: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: 520,
    },
    infoPart: {
        flexDirection: 'column',
        marginLeft: -20
    },
    infoText: {
        fontWeight: 'bold'
    },
    buttonText: {
        color: colors.black,
        marginRight: 20,
        '& > *': {
            color: colors.primary,
        }
    },
    icon: {
        color: 'black'
    },
    checked: {
        maxWidth: 720,
        marginBottom: 20
    }
}));

function Upload() {
    let query = useQuery();
    let history = useHistory();
    const {t} = useTranslation('translation');
    const classes = useStyles();
    const [user, setUser] = useState({});
    const [userId, setUserId] = useState(null);

    const [session, setSession] = useState('');
    const [agree, setAgree] = useState(false);
    const [notValidSession, setNotValidSession] = useState(false);
    const [step, setStep] = useState(1);
    const steps = [1, 2];
    const [documents, setDocuments] = useState([]);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [files, setFiles] = useState([])
    const [errorMessage, setErrorMessage] = useState('');
    const [disableInput, setDisableInput] = useState(false);
    const [lockScreen, setLockScreen] = useState(true);
    const [render, setRender] = useState(false);

    const nextStep = (symbol) => {
        setStep(symbol === '>' ? step + 1 : step - 1);
    }

    const selectedFolder = (folder) => {
        setSelectedDocument(folder)
        nextStep('>')
    }

    const redirectToThankYou = () => {
        setDisableInput(true);
        const formData = new FormData();
        files.forEach(file => {
            formData.append('files', file);
        })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data;charset=utf-8',
            },
            params: {
                UserId: userId,
                sessionId: session
            },
        };

        api.post(`Documents/addDocumentsWeb/${selectedDocument.Id}`, formData, config)
            .then(() => {
                history.push('/upload-thank-you');
            })
            .catch(() => {

            }).finally(()=>setDisableInput(false));

    };

    // const reportSession = () => {
    //     handleOpenDialog(true);
    // };

    useEffect(() => {
        if(query.get('token')){
            const _user = jwt_decode(query.get('token'));
            setUserId(_user.nameid)
            FileServices.getUser(_user.nameid)
                .then(
                    (data) => {
                        setUser(data.data);
                        localStorage.setItem('user', JSON.stringify(data.data));
                    },
                    () => {
                    }
                )
        }
    }, []);

    useEffect(() => {
        if (userId) {
            api
                .get(`Documents/DocumentTypes`)
                .then((res) => {
                    setDocuments(res.data);
                })
                .catch(() => {
                })
        }

    }, [userId])

    useEffect(() => {
        if (render && query.get('sessionId') && query.get('token')) {
            setSession(query.get('sessionId'));
            setLockScreen(false)
            localStorage.setItem('sessionId', query.get('sessionId'));
            localStorage.setItem('token', query.get('token'));

        } else {
            setNotValidSession(true)
        }
        return () => {
            setNotValidSession(false);
            setDisableInput(false);
        }

    }, [render]);

    useEffect(() => {
        if (!render) {
            setRender(true)
        }
        return () => setRender(false)
    }, []);

    if (notValidSession) {
        return (
            <Layout>
                <Grid
                    className={classes.root}
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <div className={classes.header}>
                        <Typography className={classes.heading} variant="h5"
                                    component="span">
                            {t('VISITOR')}
                        </Typography>
                        <br/>
                        <Typography className={classes.heading} variant="h6"
                                    component="span">
                            {t('VISITOR_DESCRIPTION')}
                        </Typography>
                    </div>
                </Grid>
            </Layout>
        );
    } else {
        return (
            <Layout fluid={false}>
                <UploadHeader user={user}/>

                {/*Mainview*/}
                {!lockScreen && (
                    <div className={classes.content}>
                        <Container>
                            <Stepper step={step} steps={steps} title={'SELECT_FOLDER'} extendedtitle={'ADD_CONFIRM'}/>

                            {step === 1 && (<> <h5>{t('MULTIPLE_SELECTION')}</h5> <div className={classes.documents}>
                                {has(documents) && documents.map((document, index) => <UploadFolderItem
                                    selectedFolder={selectedFolder} document={document} key={index}/>)}</div></>)}

                            {step === 2 && (<UploadDocument files={files} setFiles={setFiles} selectedDocument={selectedDocument} setError={setErrorMessage} />)}

                            {step === 2 && (<div className={classes.checked}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                        checked={agree}
                                        onChange={()=>setAgree(!agree)}
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />
                                    }
                                    label={t('ADAPTED_AND_ORDER')}
                                />
                            </div>)}
                            {step === 2 && <>
                                <Button variant="outlined" color={'secondary'} className={classes.buttonText}
                                                   onClick={() => nextStep('<')}>{t('BACK')}</Button>
                                <Button variant="contained" color={'primary'} disabled={!(has(files) === true && agree === true)}
                                        onClick={() => redirectToThankYou()}>{t('CONFIRM_CLOSE')}</Button>
                            </>}
                        </Container>
                    </div>
                )}

                {/*Mainview*/}
                <Container>
                    <Grid
                        className={classes.root}
                        container
                        direction="row"
                        alignItems="center">
                        <div className={classes.header}>
                            <Typography color={'error'}>{t(errorMessage)}</Typography>
                        </div>

                        {!lockScreen &&
                        <div className={classes.header}>
                            <ListItem>
                                <ListItemIcon>
                                    <InfoRounded className={classes.icon}/>
                                </ListItemIcon>
                                <div className={classes.infoPart}>
                                    <Typography className={classes.infoText}>
                                        {t('DO_NOT_KNOW_PATIENT')}
                                    </Typography>
                                    <Typography>
                                        {t('REPORT_REQUEST')}
                                    </Typography>
                                </div>
                            </ListItem>
                        </div>
                        }
                        <div className="clearfix"></div>
                        {disableInput && <CircularProgress/>}

                        {lockScreen && (
                            <>
                                <Typography color={'error'} component="span">
                                    {t('CLOSE_WINDOW')}
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Container>
            </Layout>
        );
    }
}

export default Upload;
