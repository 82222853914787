import React, {useEffect, useState} from 'react';
import {Grid, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import colors from "../utils/colors";
import {has} from "../utils/isArray";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 800,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 20,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    steps_title: {
        width: 800,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    root1: {
        width: 770,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 20,
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    steps_title1: {
        width: 770,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'start',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    step: {
        borderRadius: '50%',
        display: 'flex',
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderColor: colors.darkGray,
        borderWidth: '1px',
        borderStyle: 'solid',
        fontWeight: 500,
        zIndex: 2
    },
    divider: {
        width: 'calc(100% - 2px)',
        borderTopColor: colors.darkGray,
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
        position: 'absolute',
        marginTop: 15
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.dark,
        color: 'white'
    },
    stepperTitleLeft: {
        width: 150,
        textAlign: 'left',

    },
    stepperTitleCenter: {
        width: 150,
        textAlign: 'center',

    },
    stepperTitleRight: {
        width: 150,
        textAlign: 'right',
    }
}));

function Stepper({titles, step, steps}) {
    const classes = useStyles();
    const {t} = useTranslation('translation');

    return (
        <Grid
            container
        >
            <Grid item xs={9}>
                <div className={classes.root1}>
                    <div className={classes.divider}/>
                    {has(steps) && steps.map((item, index) => <div key={index}
                                                     className={[classes.step, item <= step && classes.active].join(' ')}>{item}</div>)}
                </div>
                <div className={classes.steps_title1}>
                    {has(titles) && titles.map((title, index) => <span key={index} className={classes[title.classes]}>{t(title.name)}</span>)}
                </div>
            </Grid>
        </Grid>

    );
}

export default Stepper
