import React from "react";
import {Container, Grid, makeStyles, Typography,} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";
import Footer from "./layout/Footer";
import Header from "./layout/Header";

const useStyles = makeStyles(() => ({
    folders1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        paddingTop: 60,
        paddingBottom: 20,
        marginLeft: '11%',
        marginBottom: 20,
    },
    topView: {
        marginTop: 10,
    },
    uploadText: {
        fontSize: 20,
        fontWeight: 'bold'
    },

    uploadText1: {
        width: "50%",
        marginBottom: 40,
        marginTop: 40,
        fontSize: 20,
        fontWeight: 'bold'
    },

}));

function ReportSuccess() {
    const classes = useStyles();
    const {t} = useTranslation('translation');

    return (
        <>
            <Header/>
            <div className={classes.folders1}>
        <Typography className={classes.uploadText}>
                    {t("REPORT_TITLE")}
                  </Typography>
                  <Typography className={classes.uploadText1}>
                    {t("REPORT_DESCRIPTION")}
                  </Typography>
                  <Typography className={classes.uploadText}>
                    {t("REPORT_FOOTER")}
                  </Typography>
                  </div>
                  <Footer position="fixed"/>
                  </>
    );

}

export default ReportSuccess;
