import React, {useEffect, useState} from "react";
import {Container, Grid, makeStyles, Typography,} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(() => ({
    folders1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 40,
        paddingBottom: 20,
        marginBottom: 20,
    },
    topView: {
        marginTop: 10,
    },
    uploadText: {
        width: "40%",
        marginBottom: 60,
        fontSize: 20,
    },

}));

function UploadSuccess({header = 'UPLOAD_SUCCESSFUL'}) {
    const classes = useStyles();
    const {t} = useTranslation('translation');
    let history = useHistory();
    const [count, setCount] = useState(5)


    useEffect(() => {
        const interval = setInterval(() => {
          console.log('This will run every second!');
          setCount(prev => prev - 1);
          if(count === 0){
            history.push('/');
          }
        }, 1000);
        return () => clearInterval(interval);
      }, [count, setCount]);

    return (
        <Layout
            title={header}
            fluid={false}

        >
            <Container className={classes.topView}>
                <Grid container spacing={3} className={classes.folders1}>
                    <Typography className={classes.uploadText}>
                        {t("UPLOAD_SUCCESS_DESC")}
                    </Typography>
                    <img
                        alt={'success'}
                        src="/success.png"
                        style={{
                            width: 200,
                            height: 200,
                            marginLeft: 20,
                            marginBottom: 100,
                        }}
                    ></img>
                </Grid>
            </Container>
        </Layout>
    );

}

export default UploadSuccess;
