const colors = {
    green: '#87B56AFF',
    purpleLight: '#4A90E2',
    purple: '#483d8b',
    pine: '#057f72',
    geladon: '#058c7d',
    persianLightGreen: '#069a89',
    persianGreen: '#07a997',
    keppel: '#08baa6',
    primary: '#09cdb7',
    turquoiseLighter: '#09cdb7',
    turquoiseLight: '#1fd2be',
    turquoise: '#33d6c4',
    turquoiseDark: '#46dac9',
    turquoiseDarker: '#57ddce',
    white: '#ffffff',
    gainsboro: '#ececec',
    lightGray: '#D3D3D3',
    silver: '#C0C0C0',
    darkGray: '#A9A9A9',
    gray: '#808080',
    dimGray: '#696969',
    black: '#000000',
    modal: 'rgba(52, 52, 52, 0.8)'
};
export default colors;
