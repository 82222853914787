import React from 'react';
import {Button, Grid, makeStyles} from "@material-ui/core";
import {has} from "../../utils/isArray";
import FilesList from "../share/FilesList";
import FoldersList from "../share/FoldersList";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    folders: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 30,
        paddingBottom: 20,
        marginBottom: 20,
        maxWidth: 800,
        minWidth: 800
    },
    downloadAllText: {
        minWidth: 300,
        maxWidth: 300,
        textTransform: 'capitalize',
        marginTop: 20
    },
    nextButton: {
        padding: 10,
        backgroundColor: "white",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        borderRadius: 5,
        height: 40,
        marginBottom: 50
    },
    backButtonText: {
        color: theme.palette.primary.main,
        textTransform: "capitalize",
    }
}));

function DownloadStep({
    socket,
    socketID,
    peerConnection,
    files,
    folders,
    setLoader,
    downloadProcessing,
    history
}) {
    const channelOptions = {
        ordered: true,
        protocol: 'tcp',
    };
    const {t} = useTranslation("translation");
    const classes = useStyles();

    const ConfirmUpload = () => {
        socket.emit('closeConnection', {toSocketId: socketID})
        peerConnection.close()
        socket.close();
        history.push('/completed');
    };

    //download Zip file (download all)
    const downloadFile = async () => {
        const fileChannel = peerConnection.createDataChannel('downloadZIP', channelOptions);

        fileChannel.onerror = (error) => {
            // console.log("Data Channel Error:", error);
        };

        fileChannel.onmessage = (event) => {
            // console.log("Got Data Channel Message:", event.data);
        };

        fileChannel.onopen = () => {
            setLoader(true);
            // console.log('send request of content');
            fileChannel.send(JSON.stringify('requestShare'));
        };

        fileChannel.onclose = () => {
            // console.log("The Data Channel is Closed");
            setLoader(false)
        };

        return fileChannel
    }

    return (
        <>
            <Grid container className={classes.folders}>
                {has(files) &&
                    <FilesList data={files} peerConnection={peerConnection} downloadProcessing={downloadProcessing}/>
                }
                {has(folders) &&
                    <FoldersList data={folders} peerConnection={peerConnection} downloadProcessing={downloadProcessing}/>
                }
                {/*<Button variant="contained" color="primary"*/}
                {/*        className={classes.downloadAllText} onClick={() => downloadFile()}>*/}
                {/*    {t('DOWNLOAD_DOC_ALL')}*/}
                {/*</Button>*/}
            </Grid>
            <Button
                onClick={ConfirmUpload}
                variant="outlined"
                size="small"
                className={classes.nextButton}
            >
                <p className={classes.backButtonText}>{t("CONFIRM_CLOSE")}</p>
            </Button>

        </>
    );
}

export default DownloadStep;
