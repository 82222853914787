import React, {useEffect, useState} from 'react';
import grey from '@material-ui/core/colors/grey';
import {AppBar, Button, Container, Divider, makeStyles, Menu, MenuItem, Toolbar, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {has} from '../../utils/isArray';

const useStyles = makeStyles((theme) => ({
    divider: {
        marginRight: theme.spacing(2),
        backgroundColor: grey[400],
    },
    buttonLang: {
        color: grey[400],
        '& > * + *': {
            color: grey[400],
        },
    },
    rightNav: {
        color: grey[400],
        display: 'flex'
    },
    lang: {
        textTransform: 'uppercase'
    },
    toolbar: {
        color: 'gray',
        flexGrow: 1,
        justifyContent: 'space-between'
    }
}));

function Header() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const [url, setUrl] = useState('/');
    const [language, setLanguage] = useState('de');
    const [languages, setLanguages] = useState([]);

    const {i18n} = useTranslation('translation');

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setLanguage(i18n.language);
        setLanguages(i18n.languages);
    }, [i18n.languages, i18n.language])

    useEffect(() => {
        let sessionId = localStorage.getItem('sessionId');
        let token = localStorage.getItem('token');
        if(sessionId && token){
            setUrl(`/?sessionId=${sessionId}&token=${token}`)
        }

        return () => setUrl('/')
    }, [url, setUrl])

    return (
        <AppBar color={'transparent'} position="static">
            <Container>
                <Toolbar disableGutters position="static" className={classes.toolbar}>
                    <Link className={'logo'} to={url}>
                        <img src="/logo-doctogo.png" alt="doctogo"/>
                    </Link>

                     <div className={classes.rightNav}>
                        <Divider className={classes.divider} orientation="vertical" flexItem/>
                        <Button
                            classes={{label: classes.buttonLang}}
                            aria-controls="lang-switch" aria-haspopup="true" onClick={handleClick}>
                            {language}
                        </Button>
                        
                        <Menu
                            id="lang-switch"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {has(languages) && languages.map((lang, index) => {
                                return <MenuItem key={index} onClick={() => changeLanguage(lang)}>
                                    <Typography className={classes.lang}>{lang}</Typography>
                                </MenuItem>
                            })}
                        </Menu>
                        
                    </div>
                     
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
