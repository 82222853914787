import React, {Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from './components/PageNotFound';
import ThankYou from './components/ThankYou';
import Impressum from './components/Impressum';
import DataProtection from './components/DataProtection';
import AGB from './components/AGB';
import Upload from './components/Upload';
import UploadThankYou from "./components/UploadThankYou";
import Home from './components/Home';
import UploadSuccess from "./components/UploadSuccess";
import Offsite from "./components/Offsite";
import ReportSuccess from './components/ReportSuccess';
import ConnectionFailed from './components/ConnectionFailed';
import ConnectionFailedOffsite from './components/ConnectionFailedOffsite';
import OffsiteThankYou from './components/OffsiteThankYou';

function App() {

  return (
      <Suspense fallback="loading">
            <Switch>
              {/* New component instead of peer */}
              <Route exact path="/" component={Home} />
              <Route exact path="/completed" component={UploadSuccess} />
              <Route exact path="/upload" component={Upload} />
              <Route path="/impressum" component={Impressum} />
              <Route path="/agb" component={AGB} />
              <Route path="/data-protection" component={DataProtection} />
              <Route path="/thank-you" component={ThankYou} />
              <Route path="/upload-thank-you" component={UploadThankYou} />
              <Route path="/offsite-thank-you" component={OffsiteThankYou} />
              <Route path="/offsite/:id" component={Offsite} />
              <Route path="/report" component={ReportSuccess} />
              <Route path="/failed" component={ConnectionFailed} />
              <Route path="/fail" component={ConnectionFailedOffsite} />
              <Route component={PageNotFound} />
            </Switch>
      </Suspense>
  );
}

export default App;
