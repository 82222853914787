import api from '../utils/axios';

const getSessionById = (sessionId) => {
  return api.get(`/users/${sessionId}`);
};

const reportSessionById = (sessionId) => {
  return api.post(`Session/ReportSession?sessionId=${sessionId}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
};

const downloadDocuments = (obj) => {
  return api.post(`Onsite/VerifyDownload`, obj, {
    responseType: 'blob',
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
};

const getUser = (userId) => {
  return api.get(`Users/${userId}`, {
    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
  });
};

const FileServices = {
  getSessionById,
  reportSessionById,
  downloadDocuments,
  getUser
};

export default FileServices;
