import React from 'react';
import ReactDOM from 'react-dom';
import {createTheme, ThemeProvider} from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey';
import {CssBaseline} from "@material-ui/core";
import {Router} from "react-router-dom";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import {ToastContainer} from 'react-toastify';
import 'webrtc-adapter';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import './styles/reset.scss';
import './i18n';
import App from './App';
import {createBrowserHistory} from 'history';

const history = createBrowserHistory();
Bugsnag.start({
    apiKey: '4c520ee5ed6821be1271bb2425cc668d',
    plugins: [new BugsnagPluginReact()]
  })

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

const theme = createTheme({
    palette: {
        primary: {
            main: '#09cdb7',
        },
        secondary: {
            main: grey[900]
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                html: {
                    fontFamily: 'Poppins',
                },
                body: {
                    fontFamily: 'Poppins',
                },
            },
        },
        MuiButton: {
            label: {
                color: "#f1f1f1",
            },
        },
    },
    typography: {
        fontFamily: [
            '"Poppins"',
            '-apple-system',
            'sans-serif'].join(','),
        h5: {
            fontWeight: 700
        }
    }
});

ReactDOM.render(
    <Router history={history}>
        <ThemeProvider theme={theme}>
            <ErrorBoundary>
                <CssBaseline />
                <App/>
                <ToastContainer/>
            </ErrorBoundary>
        </ThemeProvider>
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.unregister();