import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import Cache from "i18next-localstorage-cache";
import postProcessor from "i18next-sprintf-postprocessor";


const Languages = ["de", "en", "fr", "it"];

i18n
    .use(Backend)
    .use(Cache)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(postProcessor)
    .init({
        lng: 'de',
        language: 'de',
        fallbackLng: Languages,
        preload: Languages,
        supportedLngs: Languages,
        debug: false,
        // fallbackLng: Languages,
        whitelist: Languages,
        interpolation: {
            escapeValue: true, // not needed for react as it escapes by default
        },
       
    });

export default i18n;
