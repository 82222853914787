import React, {useCallback, useEffect, useState} from "react";
import {Button, Checkbox, Grid, makeStyles, Typography,} from "@material-ui/core";
import FilesList from "./share/FilesList";
import {useTranslation} from "react-i18next";
import api from "../utils/axios";
import FolderIcon from "@material-ui/icons/Folder";
import {useDropzone} from "react-dropzone";
import useShare from "../hooks/useShare";
import {has} from "../utils/isArray";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 800,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 30,
        paddingBottom: 20,
    },
    downloadText: {
        textAlign: "center",
        marginTop: 20,
        marginBottom: 10,
        width: 800,
    },
    downloadTextOR: {
        textAlign: "center",
        marginLeft: "24%",
        marginBottom: 20,
        width: 400,
    },
    dropText: {
        maxWidth: 800,
        minWidth: 800,
        marginTop: 20,
        textAlign: "center",
        height: 110
    },
    folders1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 40,
        paddingBottom: 20,
        marginBottom: 20,
    },
    folders12: {
        maxWidth: "80%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 40,
        paddingBottom: 20,
        marginBottom: 20,
    },
    supportedFiles: {
        fontSize: 12,
        textAlign: 'center',
        color: 'gray',
        padding: 10
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    paper1: {
        height: 130,
        width: 200,
        backgroundColor: "white",
        borderRadius: 2,
        cursor: "pointer",
        paddingTop: 20,
        boxShadow: "2px 2px #bdbdbd",
    },
    step2folder: {
        height: 130,
        width: 200,
        backgroundColor: "white",
        borderRadius: 2,
        cursor: "pointer",
        paddingTop: 20,
        boxShadow: "2px 2px #bdbdbd",
    },
    folderRound: {
        height: 40,
        width: 40,
        borderRadius: 25,
        backgroundColor: "#eeeeee",
        marginLeft: "40%",
    },
    folderIcon: {
        marginTop: 10,
        fontSize: 22,
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 30,
        paddingLeft: 20,
        maxWidth: 720,
        minWidth: 700,
    },
    checkboxContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 50,
        paddingLeft: 20,
        maxWidth: 720,
        minWidth: 700,
    },
    checkboxLabel: {
        marginTop: 10,
    },
    backButton: {
        padding: 10,
        marginRight: 20,
        backgroundColor: "white",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        borderRadius: 5,
        height: 40,
    },
    backButtonText: {
        color: theme.palette.primary.main,
        textTransform: "capitalize",
    },
    nextButton: {
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "white",
        fontWeight: "bold",
        height: 40,
        textTransform: "capitalize",
    },
    submitButton: {
        marginLeft: 15,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "white",
        fontWeight: "bold",
        height: 40,
        textTransform: "none",
    },
    submitView: {
        minWidth: 800,
        maxWidth: 800
    },
    rejectFile: {
        color: 'red',
        marginTop: 40,
        marginLeft: 10
    },
    customFileUpload: {
        border: "none",
        backgroundColor: "gray",
        borderColor: "black",
        borderRadius: 5,
        padding: 10,
        cursor: "pointer",
        color: "white",
        fontSize: 12,
        textAlign: 'center',
        margin: '0 auto',
        display: 'block',
        width: 150,
    },
    table: {
        marginTop: 30,
        display: "inline-table",
        margin: 1,
        marginLeft: 20,
        border: "dashed 2px",
        borderColor: "gray",
        maxWidth: 800,
        minWidth: 800,
        height: 150,
        backgroundColor: "white",
    },
    heading: {
        marginLeft: 40,
        marginTop: 50,
    },
    header: {
        marginTop: 20,
        marginBottom: 30,
        display: "flex",
        flexDirection: "row",
    },
    folderText: {
        fontSize: 14,
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        color: "black",
    },
    folderSubText: {
        fontSize: 10,
        marginTop: 16,
        textAlign: "center",
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    folderText1: {
        fontSize: 14,
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        color: "black",
    },
    folderSubText1: {
        fontSize: 10,
        marginTop: 16,
        textAlign: "center",
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    Stepper: {
        marginLeft: 30,
    },
    uploadText: {
        width: "40%",
        marginBottom: 60,
        fontSize: 20,
    },
    disabledButton: {
        backgroundColor: theme.palette.grey[400]
      }
}));

function UploadSteps({
    socket,
    socketID,
    peerConnection, 
    setLoader, 
    step, 
    setStep, 
    isUploadProcessing
}) {
    const classes = useStyles();
    let history = useHistory();
    const {sendFile} = useShare(peerConnection);
    const [documents, setDocuments] = useState([]);
    const {t} = useTranslation("translation");
    const [files, setFiles] = useState([]);
    const [item, setItem] = useState("");
    const [checkPerm, setCheckPerm] = useState(false);

    // When a file is selected or dropped to the area
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (!has(files)) {
                setFiles(acceptedFiles);
                isUploadProcessing(false)
            } else {
                const __files = acceptedFiles.map(new_file => {
                    const exists = files.find(_old_file => _old_file.name === new_file.name)
                    if(!exists){
                        return new_file;
                    }
                    toast.error(`${new_file.name + " " + t("ALREADY_EXISTS")}`, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    return false
                })

                if(has(__files)){
                    setFiles([...files, ...__files.filter(item => !!item.name)]);
                }
                isUploadProcessing(false)
            }
        }, [files]
    );

    // When the "file pick" window is closed
    const onFileDialogCancel = useCallback(() => {
        isUploadProcessing(false);
    })

    const handleChangeCheckPerm = () => {
        setCheckPerm(!checkPerm);
      };

    const fileType = [
        "image/bmp",
        "image/jpeg",
        "image/jpg",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/pdf",
        "image/png",
        "image/heic",
        "image/heif"
    ]

    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
        accept: fileType.join(','),
        onDrop,
        onFileDialogCancel,
    });
          
    const removeFile = (file) => {
        const _files = files.filter(item => item !== file);
        setFiles(_files)
    }

    const getBase64 = async (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((reslove, reject) => {
            reader.onload = () => reslove(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };

    const openFolder = (item) => {
        setStep(2);
        setItem(item);
    };

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    const uploadFiles = async () => {
        isUploadProcessing(true)
        for (let file of files) {
            setLoader(true);
            const base64 = await getBase64(file);
            await sendFile(file, base64, item);
            toast.success(`${file.name.slice(0, file.name.lastIndexOf('.')).replace(/\./g, '') + '.' + file.name.split('.').pop() + " " + t("SUCCESSFULLY_TRANSFERRED")} `, {
                position: toast.POSITION.TOP_CENTER
            });
            await delay(15000);
            setLoader(false);
        }
        isUploadProcessing(false)
        backToStepOne();
        setFiles([]);
    }

    const ConfirmUpload = async ()   => {
        isUploadProcessing(true)
        for (let file of files) {
            setLoader(true);
            const base64 = await getBase64(file);
            await sendFile(file, base64, item);
            toast.success(`${file.name.slice(0, file.name.lastIndexOf('.')).replace(/\./g, '') + '.' + file.name.split('.').pop() + " " + t("SUCCESSFULLY_TRANSFERRED")} `, {
                position: toast.POSITION.TOP_CENTER
            });
            await delay(15000);
            setLoader(false);
        }
        await delay(1000);
        socket.emit('closeConnection', {toSocketId: socketID})
        peerConnection.close();
        socket.close();
        await delay(1000);
        isUploadProcessing(false)
        history.push('/completed');
    };

    const backToStepOne = () => {
        setStep(1);
        setFiles([])
    };

    useEffect(() => {
        api.get(`Documents/DocumentTypes`).then((res) => {
            if (res) {
                setDocuments(res.data);
            }
        });

        return () => {
            setDocuments([])
        }
    }, []);

    return (
        <>
            {step === 1 && (
                <Grid container spacing={3} className={classes.folders12}>
                    {documents.map((item) => {
                        return (
                            <div className={classes.paper} key={item.Id}>
                                <div
                                    className={classes.paper1}
                                    onClick={() => openFolder(item)}
                                >
                                    <div className={classes.folderRound}>
                                        <FolderIcon className={classes.folderIcon}/>
                                    </div>
                                    <Typography className={classes.folderText}>
                                        {t(item.Type)}
                                    </Typography>
                                    <Typography className={classes.folderSubText}>
                                    </Typography>
                                </div>
                            </div>
                        );
                    })}
                    <div className={classes.buttonContainer}>
                        <Button
                            onClick={ConfirmUpload}
                            mode="outline"
                            size="small"
                            className={classes.nextButton}
                        >
                            {t("CONFIRM_CLOSE")}
                        </Button>
                    </div>
                </Grid>
            )}
            {step === 2 && (
                <>
                    <Grid container spacing={3} className={classes.folders1}>
                        <div className={classes.paper}>
                            <div className={classes.step2folder}>
                                <div className={classes.folderRound}>
                                    <FolderIcon className={classes.folderIcon}/>
                                </div>
                                <Typography className={classes.folderText1}>
                                    {t(item.Type)}
                                </Typography>
                                <Typography className={classes.folderSubText1}>
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.paper}>
                            <FilesList
                                peerConnection={peerConnection}
                                deleteFlag={true}
                                removeFile={removeFile}
                                data={files}
                            />
                        </div>
                        
                        <div className={classes.table}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} onClick={() => isUploadProcessing(true)} />
                                {isDragActive ? (
                                    <p className={classes.dropText}>{t("DROP_HERE")}</p>
                                ) : (
                                    <>
                                   
                                        <p className={classes.downloadText}>
                                            {t("DRAG_DROP")}
                                        </p>
                                        <p className={classes.downloadTextOR}>{t("OR")}</p>
                                        <label className={classes.customFileUpload}>
                                            {" "}
                                            {t("SELECT_DOCUMENT")}
                                        </label>
                                        <p className={classes.supportedFiles}>{t("SUPPORTED_FILES") }:   .jpg, .jpeg, .bmp, .png, .pdf, .docx, .doc,
                                                       .heic, .heif
                                        </p>
                                       
                                   
                                    </>
                                )}
                           
                            </div>
                            {fileRejections.length > 0 && <p className={classes.rejectFile}> *{t("UNSUPORTED_FILES")}</p>}
                        </div>
                        <div className={classes.checkboxContainer}>
                            <Checkbox onChange={handleChangeCheckPerm} checked={checkPerm}></Checkbox>
                            <label className={classes.checkboxLabel}>
                                {t("CHECKBOX_PERMISSIONS")}
                            </label>
                        </div>
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="outlined"
                                className={classes.backButton}
                                size="small"
                                onClick={backToStepOne}
                            >
                                <p className={classes.backButtonText}>{t("BACK")}</p>{" "}
                            </Button>
                            <Button
                                onClick={ConfirmUpload}
                                mode="outline"
                                disabled={!checkPerm}
                                size="small"
                                className={classes.nextButton}
                                classes={{ disabled: classes.disabledButton }}
                            >
                                {t("CONFIRM_CLOSE")}
                            </Button>
                            {files.length > 0 &&
                            <Button
                                mode="outline"
                                size="small"
                                disabled={!checkPerm}
                                className={classes.submitButton}
                                classes={{ disabled: classes.disabledButton }}
                                onClick={() => uploadFiles()}
                            >
                                {t("SUBMIT")}
                            </Button>
                        }
                        </div>
                    </Grid>
                </>
            )}
        </>
    );
}

export default UploadSteps;
