import React from "react";
import FolderIcon from "@material-ui/icons/Folder";
import FilesList from "./FilesList";
import {useTranslation} from "react-i18next";

import {Grid, makeStyles, Typography,} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    listItem: {
        backgroundColor: "white",
        boxShadow: "2px 2px #bdbdbd",
        borderRadius: 3,
        padding: 10,
        marginBottom: 10,
        maxWidth: 800,
        minWidth: 800,
        maxHeight: 40,
        minHeight: 40
    },
    folders1: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 15,
        paddingLeft: 10
    },
    folders12: {
        maxWidth: "80%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 40,
        paddingBottom: 20,
        marginBottom: 20,
        marginLeft: 20,
    },
    paper: {
        // padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    paper1: {
        height: 130,
        width: 200,
        backgroundColor: "white",
        borderRadius: 2,
        cursor: "pointer",
        paddingTop: 20,
        boxShadow: "2px 2px #bdbdbd",
    },
    step2folder: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: "white",
        boxShadow: "2px 2px #bdbdbd",
        borderRadius: 3,
        padding: 10,
        marginBottom: 20,
        maxWidth: 800,
        minWidth: 800,
        maxHeight: 40,
        minHeight: 40
    },
    folderRound: {
        height: 40,
        width: 40,
        borderRadius: 25,
        backgroundColor: "#eeeeee",
        marginLeft: "40%",
    },
    folderIcon1: {
        marginTop: 10,
        fontSize: 22,
    },
    folderRound1: {
        height: 40,
        width: 40,
        borderRadius: 20,
        backgroundColor: "#eeeeee",
        marginLeft: "30%",
        marginTop: -12,
    },
    folderIcon: {
        color: '#C0C0C0'
    },
    butonContainer: {
        display: "flex",
        flexDirection: "row",
        marginTop: 30,
        paddingLeft: 20,
        maxWidth: 720,
        minWidth: 700,
    },
    checkboxLabel: {
        marginTop: 10,
    },
    backButon: {
        padding: 10,
        marginRight: 20,
        backgroundColor: "white",
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        fontWeight: "bold",
        borderRadius: 5,
        height: 40,
    },
    backButonText: {
        color: theme.palette.primary.main,
    },
    nextButon: {
        padding: 10,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        color: "white",
        fontWeight: "bold",
        height: 40,
    },
    customfileupload: {
        border: "line 2px",
        backgroundColor: "gray",
        borderColor: "black",
        borderRadius: 5,
        padding: 10,
        cursor: "pointer",
        color: "white",
        fontSize: 12,
        marginLeft: "39%",
        width: 300,
    },
    table: {
        marginTop: 30,
        display: "inline-table",
        margin: 1,
        marginLeft: 20,
        border: "dashed 2px",
        borderColor: "gray",
        maxWidth: 800,
        minWidth: 800,
        height: 150,
        backgroundColor: "white",
    },
    heading: {
        marginLeft: 40,
        marginTop: 50,
    },
    header: {
        marginTop: 20,
        marginBottom: 30,
        display: "flex",
        flexDirection: "row",
    },
    folderText: {
        fontSize: 14,
        marginTop: 10,
        textAlign: "center",
        fontWeight: "bold",
        color: "black",
    },
    folderSubText: {
        fontSize: 10,
        marginTop: 16,
        textAlign: "center",
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    folderText1: {
        paddingLeft: 35
    },
    folderSubText1: {
        fontSize: 10,
        marginTop: 16,
        textAlign: "center",
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    Stepper: {
        marginLeft: 30,
    },
    topView: {
        marginTop: 10,
    },
}));
export default function Folder({data, peerConnection, downloadProcessing}) {
    const {t} = useTranslation('translation');
    const classes = useStyles();

    return (
        <>
            <Grid container spacing={3} className={classes.folders1}>
                <div className={classes.paper}>
                    <div className={classes.step2folder}>
                        {/* <div className={classes.folderRound}> */}
                            <FolderIcon className={classes.folderIcon} />
                        {/* </div> */}

                        <Typography className={classes.folderText1}>
                            {t(data.Type)}
                        </Typography>
                        {/* <Typography className={classes.folderSubText1}>
                            {data.files.length} {t("DOCUMENTS")}
                        </Typography> */}
                    </div>
                </div>
                <div className={classes.paper}>
                    <FilesList data={data.files} peerConnection={peerConnection} downloadProcessing={downloadProcessing}/>
                </div>
            </Grid>
        </>
    );
}
