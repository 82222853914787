import {Grid, makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 720,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 30,
        paddingBottom: 30
    },
    header: {
        marginBottom: 30
    }
}));

function Impressum() {
    const {t} = useTranslation('translation');
    const classes = useStyles();
    return (
        <Layout>
            <Grid
                className={classes.root}
                container
                direction="column"
                justifyContent="center"
                alignItems="center">

                <Typography variant={'h3'} className={classes.header}>
                    {t('IMPRESSUM')}
                </Typography>
                <Typography paragraph className={classes.header}>
                    {t('IMPRESSUM_PARAGRAPH_1')}
                </Typography>
                <Typography paragraph className={classes.header}>
                    {t('IMPRESSUM_PARAGRAPH_2')}
                </Typography>
                <Typography paragraph>
                    {t('OWNER')}
                </Typography>
                <Typography paragraph>
                    Doctogo GmbH
                </Typography>
                <Typography paragraph>
                    Poststrasse 1
                </Typography>
                <Typography paragraph>
                    8303 Bassersdorf
                </Typography>
                <Typography paragraph>
                    {t('SWISS')}
                </Typography>
                <Typography paragraph className={classes.header}>
                    {t('E-MAIL')}: info@doctogo.ch
                </Typography>
            </Grid>
        </Layout>
    );

}

export default Impressum;
