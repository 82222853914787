import React from 'react';
import {
    Container,
    Divider,
    Grid,
    Link,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import grey from '@material-ui/core/colors/grey';
import common from '@material-ui/core/colors/common';
import {useTranslation} from "react-i18next";
import {Facebook, LinkedIn, Instagram} from "@material-ui/icons";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            backgroundColor: '#09CDB7',
            color: common['white'],
            height: 300
        },
        root1: {
            position: "fixed",
            width: "100%",
            bottom: 0
            },
        miniFooter: {
            display: "flex",
            flex: 1,
            flexDirection: 'row',
            '& > * + *': {
                marginLeft: theme.spacing(2),
            },
        },
        grid: {
            flexGrow: 1,
            padding: '20px 0',
        },
        childFooter: {
            padding: '20px 0',
            backgroundColor: '#08B29F',
            color: common['white']
        },
        divider: {
            backgroundColor: common['white']
        }, 
        sms: {
            marginLeft: 'auto',
            marginTop: 180
        },
        footerHeading: {
            color: "white",
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: 18
        },
        addressText: {
            color: common['white'],
            textTransform: 'none',
            paddingLeft: 0,
            paddingBottom: 0
        },
        addressTextNew: {
            color: common['white'],
            textTransform: 'none',
            display: 'flex',
            flexDirection: 'column',
        },
        icon: {
            color: 'white'
        },
        address: {
            paddingTop: 10
        }
    }
})

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
}

function Footer(position) {
    const classes = useStyles();
    const {t, i18n} = useTranslation('translation');
    return (
    <div className={position.position === "fixed"? classes.root1 : classes.root}>
        <footer className={classes.root}>
            <Container maxWidth="lg" className={classes.root}>
                <Grid container className={classes.grid} spacing={4}>
                    <Grid item xs={12} md={2}>
                        <Typography className={classes.footerHeading} variant="h6" component="h6">
                            {t('ADDRESS_HEADING')}
                        </Typography>
                        <Link target={'_blank'}
                              className={classes.addressTextNew}
                              rel="noopener"
                              href={'https://www.google.com/maps/search/?api=1&query=Poststrasse 1,%208303%20Bassersdorf,%20Swiss'}>
                            <span className={classes.address}>{t('ADDRESS')}</span>
                            <span className={classes.address}>{t('ADDRESS_2')}</span>
                            <span className={classes.address}>{t('SWISS')}</span>
                            <span className={classes.address}>support@doctogo.ch</span>
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography className={classes.footerHeading} variant="h6" component="h6">
                            {t('SOCIAL_MEDIA_HEADING')}
                        </Typography>
                        <ListItemLink className={classes.addressText} href={'https://www.linkedin.com/company/doc2go/'} target={'_blank'}
                                      rel="noreferrer">
                            <ListItemIcon>
                                <LinkedIn className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary="LinkedIn"/>
                        </ListItemLink>
                        <ListItemLink className={classes.addressText} href={'https://www.facebook.com/Doctogo-312145986612275/'} target={'_blank'}
                                      rel="noreferrer">
                            <ListItemIcon>
                                <Facebook className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary="Facebook"/>
                        </ListItemLink>
                        <ListItemLink className={classes.addressText} href={'https://www.instagram.com/_doctogo/'} target={'_blank'}
                                      rel="noreferrer">
                            <ListItemIcon>
                                <Instagram className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary="Instagram"/>
                        </ListItemLink>
                    </Grid>

                    <Grid className={classes.sms} item xs={12} md={2}>
                        <a href={i18n.language === 'de' ? 'https://www.swissmadesoftware.org/de/' : 'https://www.swissmadesoftware.org/en/'}>
                            <img src="/logo.png" alrt={'sms'} className={'sms-logo'} alt='logo' />
                        </a>
                    </Grid>
                </Grid>
            </Container>
        </footer>
        <div className={classes.childFooter}>
            <Container >
                <div className={classes.miniFooter}>
                    <Link href="/impressum" color="inherit">
                        {t('IMPRESSUM')}
                    </Link>
                    {/* <Divider className={classes.divider} orientation="vertical" flexItem variant={'inset'}/>
                    <Link href="/agb" color="inherit">
                        {t('AGB')}
                    </Link> */}
                    <Divider className={classes.divider} orientation="vertical" flexItem variant={'inset'}/>
                    <Link href="/data-protection" color="inherit">
                        {t('DATA_PROTECTION')}
                    </Link>
                </div>
            </Container>
        </div>
    </div>);
}

export default Footer;
