import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {CircularProgress, Container, Grid, makeStyles, Modal, Typography} from "@material-ui/core";
import {useTranslation} from 'react-i18next';
import OffsiteHeader from '../OffsiteHeader';
import FooterMobile from './FooterMobile';
import { isMobile } from "react-device-detect";
const useStyles = makeStyles(() => ({
    root: {
        minHeight: 'calc(100vh - 340px)',
    },
    loader: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    HeaderContainer: {
        minHeight: 200
    },
    topView1: {
        paddingTop: 50,
        minWidth: 700,
        maxWidth: 720,
        fontWeight: 'bold'
    },
    mobileView: {
        paddingTop: 50,
        minWidth: 250,
        maxWidth: 400,
        fontWeight: 'bold'
    }
}));

function Layout({children, loader = false, setLoader = () => {}, fluid = true, title="", user, header, headerDesc, headerDescStep2, step}) {
    const classes = useStyles();
    const {t} = useTranslation('translation');
    return (
        <>
            <Modal
                open={loader}
                onClose={setLoader}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div className={classes.loader}><CircularProgress /></div>
            </Modal>
            <Header/>
            {title &&  <Container className={classes.HeaderContainer}>
                {(() => {
                    if (title === "offsite") {
                        return <OffsiteHeader user={user} header={header} headerDesc={headerDesc} headerDescStep2={headerDescStep2} step={step} />
                    }
                    else if (title === "MOBILE_NOT_SUPPORTED") {
                        return <Typography className={classes.mobileView} variant="h6">{t(title)}</Typography>
                    }
                    else {
                        return <Typography className={classes.topView1} variant="h4">
                            {t(title)}
                        </Typography>
                    }
                })()
                }
                </Container>
            }
           
            
            <Container disableGutters={!fluid} style={{background: '#eeeeee'}} maxWidth={fluid ? 'lg' : false}>
                {!fluid && children}
                {fluid && <Grid
                    className={classes.root}
                    container
                    direction="row"
                    alignItems="center"
                >
                    {children}
                </Grid>}

            </Container>
            {!isMobile ? 
            <Footer/>
            : 
            <FooterMobile/>
            }
        </>
    );
}

export default Layout;
