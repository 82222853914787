import {Grid, makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 720,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 30,
        paddingBottom: 30
    },
    heading: {
        marginRight: 5
    },
    header: {
        marginBottom: 30
    },
}));

function DataProtection() {
    const {t} = useTranslation('translation');
    const classes = useStyles();
    return (
        <Layout>
            <Grid
                className={classes.root}
                container
                direction="column"
                justifyContent="center"
                alignItems="center">

                <Typography variant={'h3'} className={classes.header}>
                    {t('DATA_PROTECTION_PAGE.TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.TITLE_DATE')}</Typography>
                <Typography variant={'h4'} className={classes.header}>
                    1.{' '}{t('DATA_PROTECTION_PAGE.TITLE_GENERAL')}
                </Typography>
                
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.GENERAL_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.GENERAL_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.GENERAL_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.GENERAL_PARAGRAPH_4')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.GENERAL_PARAGRAPH_5')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.GENERAL_PARAGRAPH_6')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.GENERAL_PARAGRAPH_7')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    2.{' '}{t('DATA_PROTECTION_PAGE.DOWNLOAD_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_4')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_5')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_6')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_7')}</Typography>
                <a href={t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_LINK_1')}>
                    <Typography className={classes.header}>-{' '}{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_LINK_1')}</Typography>
                </a>
                <a href={t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_LINK_2')}>
                    <Typography className={classes.header}>-{' '}{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_LINK_2')}</Typography>
                </a>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_8')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DOWNLOAD_PARAGRAPH_9')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    3.{' '}{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_TITLE')}
                </Typography>
                <Typography variant={'h5'} className={classes.header}>3.1{' '}{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_1_TITLE')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_1_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_1_PARAGRAPH_2')}</Typography>

                <Typography variant={'h5'} className={classes.header}>
                    3.2{' '}{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_2_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_2_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_2_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_2_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_2_PARAGRAPH_4')}</Typography>

                <Typography variant={'h5'} className={classes.header}>
                    3.3{' '}{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_4')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_5')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_6')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_7')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_3_PARAGRAPH_8')}</Typography>

                <Typography variant={'h5'} className={classes.header}>
                    3.4{' '}{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_4_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_4_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_4_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_4_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FOR_DOSIER_SUB_4_PARAGRAPH_4')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    4.{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_PARAGRAPH_1')}</Typography>

                <Typography variant={'h5'} className={classes.header}>
                    4.1{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_4')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_5')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_6')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_7')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_1_PARAGRAPH_8')}</Typography>

                <Typography variant={'h5'} className={classes.header}>
                    4.2{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_PARAGRAPH_4')}</Typography>

                <Typography variant={'h6'} className={classes.header} style={{textDecoration: 'underline'}}>
                    a){' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>
                    {t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_2_1')}{' '}
                    <a href={"https://"+t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_LINK')}>
                        {t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_LINK')}
                    </a>
                    {' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_2_2')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>
                    {t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_4_1')}{' ('}
                    <a href={"https://"+t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_LINK')}>
                        {t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_LINK')}
                    </a>
                    {') '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_4_2')}
                </Typography>
                <Typography className={classes.header}>
                    {t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_5_1')}{' ('}
                    <a href={"https://"+t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_LINK')}>
                        {t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_LINK')}
                    </a>
                    {') '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_5_2')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_A_PARAGRAPH_6')}</Typography>

                <Typography variant={'h6'} className={classes.header} style={{textDecoration: 'underline'}}>
                    b){' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_B_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_B_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_B_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_B_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_2_B_PARAGRAPH_4')}</Typography>
                
                <Typography variant={'h5'} className={classes.header}>
                    4.3{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_3_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_3_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{'\u2B24'}{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_3_PARAGRAPH_1_1')}</Typography>
                <Typography className={classes.header}>{'\u2B24'}{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_3_PARAGRAPH_1_2')}</Typography>
                <Typography className={classes.header}>{'\u2B24'}{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_3_PARAGRAPH_1_3')}</Typography>
                <Typography className={classes.header}>{'\u2B24'}{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_3_PARAGRAPH_1_4')}</Typography>
                <Typography className={classes.header}>{'\u2B24'}{' '}{t('DATA_PROTECTION_PAGE.DATE_FROM_DOSIER_SUB_3_PARAGRAPH_1_5')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    5.{' '}{t('DATA_PROTECTION_PAGE.FURTHER_INFO_TITLE')}
                </Typography>
                <Typography className={classes.header}>
                    {t('DATA_PROTECTION_PAGE.FURTHER_INFO_PARAGRAPH_1')}{' '}
                    <a href={t('DATA_PROTECTION_PAGE.FURTHER_INFO_MATOMO_LINK')}>
                        {t('DATA_PROTECTION_PAGE.FURTHER_INFO_MATOMO_LINK')}
                    </a>  
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.FURTHER_INFO_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.FURTHER_INFO_PARAGRAPH_3')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    6.{' '}{t('DATA_PROTECTION_PAGE.REVOCATION_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.REVOCATION_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>
                    {t('DATA_PROTECTION_PAGE.REVOCATION_PARAGRAPH_2_1')}{' ('}
                    <a href={"mailto:"+t('DATA_PROTECTION_PAGE.REVOCATION_DOC_INFO_MAIL')}>
                        {t('DATA_PROTECTION_PAGE.REVOCATION_DOC_INFO_MAIL')}
                    </a>  
                    {') '}{t('DATA_PROTECTION_PAGE.REVOCATION_PARAGRAPH_2_2')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.REVOCATION_PARAGRAPH_3')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    7.{' '}{t('DATA_PROTECTION_PAGE.WARRANTY_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.WARRANTY_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.WARRANTY_PARAGRAPH_2')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.WARRANTY_PARAGRAPH_3')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.WARRANTY_PARAGRAPH_4')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    8.{' '}{t('DATA_PROTECTION_PAGE.LAW_TITLE')}
                </Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.LAW_PARAGRAPH_1')}</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.LAW_PARAGRAPH_2')}</Typography>

                <Typography variant={'h4'} className={classes.header}>
                    9.{' '}{t('DATA_PROTECTION_PAGE.TOS_TITLE')}
                </Typography>
                <Typography className={classes.header}>
                    {t('DATA_PROTECTION_PAGE.TOS_PARAGRAPH_1_1')}{' '}
                    <a href={"mailto:"+t('DATA_PROTECTION_PAGE.TOS_INFO_EMAIL')}>
                        {t('DATA_PROTECTION_PAGE.TOS_INFO_EMAIL')}
                    </a>  
                    {' '}{t('DATA_PROTECTION_PAGE.TOS_PARAGRAPH_1_2')}
                </Typography>
                <Typography className={classes.header} style={{alignSelf: 'center'}}>- - - - -</Typography>
                <Typography className={classes.header}>{t('DATA_PROTECTION_PAGE.PRIVACY_POLICY_DATE_AND_PLACE')}</Typography>
            </Grid>
        </Layout>
    );
}

export default DataProtection;
