export const slugify = string => {
    if (!string) {
        return false;
    }
    const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;';
    const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

export const shortify = (string, length = 55) => {
    if (!string) {
        return false;
    }

    if (string.length <= length) {
        return string;
    }

    return string.substring(0, length);
};

export const is_boolean = (value, valid_class, invalid_class) => {
    if (value === true || value === false) {
        return value ? valid_class : invalid_class;
    }

    return '';
};

export function is_object(value) {
    if (value === null) {
        return false;
    }
    return Object.entries(value).length > 0 && value.constructor === Object;
}

export function isFunction(functionToCheck) {
    return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === '[object Function]'
    );
}
