import React, {useCallback} from 'react';
import {Button, makeStyles} from "@material-ui/core";
import colors from "../../utils/colors";
import {Folder} from "@material-ui/icons";
import {useTranslation} from "react-i18next";
import {useDropzone} from 'react-dropzone';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import ImageIcon from '@material-ui/icons/Image';

const FileTypes = [
    {
        type: 'application/msword',
        icon: 'word',
        color: { color: 'blue'},
    },
    {
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        icon: 'word',
        color: { color: 'blue', marginRight: 10},
    },
    {
        type: 'application/pdf',
        icon: 'pdf',
        color: { color: 'red', marginRight: 10},
    },
    {
        type: 'image/',
        icon: 'image',
        color:{ color: 'green', marginRight: 10},
    },
];

const Icons = {
    'image' : ImageIcon,
    'pdf' : PictureAsPdfIcon,
    'word' : DescriptionIcon,
}

const UploadIcon = ({type}) => {
    try {
        const typeObject = FileTypes.find((x) => type.includes(x.type));
        if (Icons[typeObject.icon]) {
            const Icon = Icons[typeObject.icon];
            return <Icon style={typeObject.color}/>;
        }
        return <DescriptionIcon color={colors.purple}/>;
    } catch (error) {
        return <DescriptionIcon color={colors.purple}/>;
    }
};

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 720,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 20,
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 4,
        marginBottom: 20
    },
    documentIcon: {
        borderRadius: '50%',
        padding: 20,
        backgroundColor: colors.lightGray,
        display: 'flex',
        marginBottom: 10
    },
    documentName: {
        fontWeight: 'bold',
        color: colors.black
    },
    icon: {
        fontSize: 40,
        color: colors.gray
    },
    dropArea: {
        width: '100%',
        height: 200,
        backgroundColor: colors.white,
        border: '1px dashed black',
        padding: 20,
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    files: {
        marginBottom: 20,
        width: '100%',
    },
    file: {
        marginBottom: 20,
        width: '100%',
        padding: 7,
        borderRadius: 4,
        backgroundColor: colors.white,
        display: 'flex',
        alignItems: 'center',
        '&:last-child' : {
            marginBottom: 0
        }
    },
    deleteButton:{
        marginLeft: 'auto',
    },
    deleteIcon:{
        color: colors.black
    }
}));

function UploadDocument({selectedDocument, files, setFiles, setError}) {
    const classes = useStyles();
    const {t} = useTranslation('translation');

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.find((f) => f.size > 10000000)) {
            setError('MAXIMUM_UPLOAD_SIZE_EXCEEDED')
        } else {
            setFiles(prevState => [...prevState, ...acceptedFiles])
        }
    }, [setError, setFiles])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: 'application/pdf,.pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document,.docx,application/msword,.doc, image/bmp,.bmp, image/jpeg,.jpg,.jpeg, image/png,.png'
    })

    const removeFile = (file) => {
        const _files = files.filter(f => f !== file)
        setFiles(_files)
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.documentIcon}>
                    <Folder className={classes.icon}/>
                </div>
                <div className={classes.documentName}>{t(selectedDocument.Type)}</div>
            </div>
            <div className={classes.files}>
                {files.map((file, index) => <div key={index} className={classes.file}>
                    <UploadIcon type={file.type} />
                    {file.name}
                    <Button onClick={()=> removeFile(file)} variant={'text'} className={classes.deleteButton}> <DeleteForeverIcon className={classes.deleteIcon}/></Button>
                </div>)}
            </div>
            <div {...getRootProps()} className={classes.dropArea}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p style={{textAlign: 'center'}}>{t('DRAG_DROP')}</p> :
                        <p style={{textAlign: 'center'}}>{t('DRAG_DROP')}, <br/> {t('OR')} <br/> {t('SELECT_DOCUMENT')} </p>
                }
            </div>
        </div>
    );

}

export default UploadDocument;
