import React from 'react';
import {Container, Grid, makeStyles, Typography} from "@material-ui/core";
import colors from "../../utils/colors";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 720,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 20,
    },
    captcha: {
        marginBottom: 10,
    },
    input: {
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            width: '31%',
            marginRight: '1%',
            marginLeft: '1%',
            marginBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            width: 56,
            marginRight: 10,
            marginLeft: 0,
        },
        textTransform: 'capitalize',
    },
    heading: {
        marginRight: 5,
    },
    form: {
        marginBottom: 30,
    },
    header: {
        marginBottom: 30,
    },
    icon: {
        color: 'black'
    },
    infoPart: {
        flexDirection: 'column',
        marginLeft: -20
    },
    infoText: {
        fontWeight: 'bold'
    },
    content: {
        backgroundColor: colors.darkGray
    }
}));

function UploadHeader({user}) {
    const classes = useStyles()
    const {t} = useTranslation('translation');
    return (
        <Container>
            <Grid
                className={classes.root}
                container
                direction="row"
                alignItems="center"
            >
                <div className={classes.header}>
                    <Typography className={classes.heading} variant="h5" component="span">
                        {t('PACIENT_HEADING_1')}
                    </Typography>
                    {user &&
                    <Typography className={classes.heading} variant="h5" component="span">
                        {user && user.IsMale === true ? (
                            t('MALE')
                        ) : (
                            t('FEMALE')
                        )}
                    </Typography>
                    }
                    <Typography
                        className={classes.heading}
                        color={'primary'}
                        variant="h5"
                        component="span"
                    >
                        {user && user.FirstName} {user && user.LastName}
                    </Typography>
                    <Typography className={classes.heading} variant="h5" component="span">
                        {t('PACIENT_HEADING_2')}
                    </Typography>
                </div>
            </Grid>
        </Container>
    );

}

export default UploadHeader;
