import React, {useEffect, useState} from 'react';
import api from "../utils/axios";

function usePeer(connected, socket) {

    const channelOptions = {
        ordered: true,
        protocol: 'tcp',
    };
    const [channel, setChannel] = useState(null);
    const [config, setConfig] = useState(false);
    const [signalStatus, setSignalStatus] = useState('New');
    const [onlineStatus, setOnlineStatus] = useState('New');
    const [peerConnection, setPeerConnection] = useState(null);
    const [socketID, setSocketID] = useState(null);
    const [answer, setAnswer] = useState();
    const [remote, setRemote] = useState(false);

    const sendToPeer = (messageType, payload, toSocketId) => {
        const object = {
            toSocketId,
            payload
        };

        // console.log('messageType ', messageType)
        try {
            if (socket) {
                socket.emit(messageType, object)
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (!config) {
            api.get('/StunTurn/GetRelay').then(data => {
                // console.log('got keys for turn/stun')
                const c = data.data[0]['Credential'];
                const u = data.data[0]['Username'];

                const pc_config = {
                    iceServers: [
                        {
                            urls: ["stun:13.107.17.41:3478", "turn:13.107.17.41:3478"],
                            username: u,
                            credential: c
                        },
                        {
                            urls: ["stun:turn.azure.com:3478", "turn:turn.azure.com:3478"],
                            username: u,
                            credential: c
                        }
                    ],
                };

                setPeerConnection(new RTCPeerConnection(pc_config))
                setConfig(true)
            })
        }

        return () =>{
            setOnlineStatus('New')
            setSignalStatus('New')
        }

    }, [])

    useEffect(() => {
        if (answer && !remote && peerConnection.signalingState === 'have-local-offer') {
            // console.log('is ready to set anser as remote')
            peerConnection.setRemoteDescription(answer).then(() => {
                setRemote(true)
            }).catch(e => console.log(e))
        }
    }, [answer, remote, peerConnection])

    useEffect(() => {
        if (connected && config) {
            try {
                socket.on('answer', (data) => {
                    // console.log('got answer from remote')
                    setAnswer(data);
                })

                socket.on('setClient', (clientId) => {
                    setSocketID(clientId)
                    // console.log('got the client_id', )
                    peerConnection.createOffer({iceRestart: true})
                        .then(async offer => {
                                await peerConnection.setLocalDescription(offer).catch(e => console.log(e))
                            sendToPeer('offer', offer, clientId)
                        }).catch(e => console.log(e))
                })
            } catch (e) {
                console.log(e)
            }

            socket.on('candidate', (data) => {
                if (peerConnection && data) {
                    // console.log('got the condidate ', data);
                    peerConnection.addIceCandidate(new RTCIceCandidate(data)).catch(e => console.log(e))
                }
            })

            socket.on('closedConnection',  (data) => {
                /// console.log('socket signal is closed ');
                peerConnection.close();
                socket.close();
                window.location.reload();
            });

            peerConnection.close = e => {
                // console.log('peer connection is closed ');
                setOnlineStatus('Closed')
                setSignalStatus('Closed')
            };

            peerConnection.onconnectionstatechange = ev => {
                // console.log('onconnectionstatechange ');
                switch (peerConnection.connectionState) {
                    case "new":
                    case "checking":
                        setOnlineStatus("Connecting...");
                        break;
                    case "connected":
                        setOnlineStatus("Online");
                        break;
                    case "disconnected":
                        setOnlineStatus("Closed");
                        break;
                    case "closed":
                        setOnlineStatus("Closed");
                        break;
                    case "failed":
                        setOnlineStatus("Closed");
                        break;
                    default:
                        setOnlineStatus("Unknown");
                        break;
                }
            }

            peerConnection.onsignalingstatechange = ev => {
                // console.log('peerConnection.onicecandidateerror ', ev);
                setSignalStatus(peerConnection.signalingState);
            };

            peerConnection.oniceconnectionstatechange = event => {
                //console.log('peerConnection.onicecandidateerror ', event);
            };

            peerConnection.onicecandidateerror  = e => {
                // console.log(e)
            }

            peerConnection.oniceconnectionstatechange = e => {
                //console.log('peerConnection.iceConnectionState ', peerConnection.iceGatheringState);
            };

            peerConnection.close = e => {
                setOnlineStatus('New')
                setSignalStatus('New')
                // console.log('afer connection is closed ');
            };;

            peerConnection.onicecandidate = e => {
                if (e.candidate && socketID) {
                    // console.log('sent canidate to other clients ');
                    sendToPeer('candidate', e.candidate, socketID);
                }
            };
            const _channel = peerConnection.createDataChannel('sendFile', channelOptions);
            setChannel(_channel);
        }
    }, [config, peerConnection, signalStatus, onlineStatus, connected])

    return {
        onlineStatus,
        socketID,
        peerConnection
    }
}

export default usePeer;
