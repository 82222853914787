import React from 'react';
import {Button, makeStyles} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Folder} from "@material-ui/icons";
import colors from "../../utils/colors";

const useStyles = makeStyles(() => ({
    root: {
        width: 150,
        height: 150,
        backgroundColor: 'white',
        marginBottom: 20,
        marginRight: 20,
        borderRadius: 4,

        '&:hover': {
            backgroundColor: colors.white100
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    documentIcon: {
        borderRadius: '50%',
        padding: 20,
        backgroundColor: colors.gainsboro,
        display: 'flex',
        marginBottom: 10
    },
    documentName: {
        fontWeight: 'bold',
        color: colors.black
    },
    icon: {
        fontSize: 40,
        color: colors.gray
    }
}));

function UploadFolderItem({document, selectedFolder}) {
    const classes = useStyles();
    const {t} = useTranslation('translation');
    return (
        <Button variant={'text'} onClick={() => selectedFolder(document)} className={classes.root}>
            <div className={classes.content}>
                <div className={classes.documentIcon}>
                    <Folder className={classes.icon}/>
                </div>
                <div className={classes.documentName}>{t(document.Type)}</div>
            </div>

        </Button>
    );

}

export default UploadFolderItem;
