import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';
import { SOCKET_URL } from '../utils/constants';

function useSocketOffline(sessionId) {
    const SOCKET_PATH = `${SOCKET_URL}/webrtcPeerOffline`;

    const [socket, setWS] = useState({connected: false});
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        if (!connected && sessionId) {
            
            // const _socket = io.connect(SOCKET_PATH, {path: '/io/webrtc', transports: ['websocket', 'polling']})
            const _socket = io.connect(SOCKET_PATH, {path: '/io/webrtc'})
            _socket.on('connect', () => {
                setConnected(true);
                // console.log('connect ')
            });

            _socket.on('disconnect', () => {
                setConnected(false);
                // console.log('disconnect ')
            });

            _socket.on('connect_error', function (err) {
                setConnected(false);
                // console.log('err ', err)
            });

            _socket.on('connection-success', success => {
                // console.log('success ', success)
            });

            setWS(_socket);
        }
    }, [sessionId]);

    return {socket, connected};
}

export default useSocketOffline;
