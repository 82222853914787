import React from 'react';
import {shortify, slugify} from "../utils/helpers";

function useShare(peerConnection) {
    const channelOptions = {
        ordered: true,
        protocol: 'tcp',
    };
    function chunkSubstr(str, size) {
        const numChunks = Math.ceil(str.length / size);
        const chunks = new Array(numChunks);

        for (let i = 0, o = 0; i <= numChunks; ++i, o += size) {
            chunks[i] = str.substr(o, size);
        }

        return chunks;
    }

    const sendFileBinary64 = async (file64, channel) => {
        try {
            const chunkSize = 20 * 1024;
            channel.send('START');
            const files = chunkSubstr(file64, chunkSize);

            await Promise.all(
                files.map(async file => {
                    channel.send(file);
                    return file;
                }),
            )

            channel.send('END');

            return true;
        } catch (e) {
            console.log(e);
        }
    };

    const sendFile = async (document, file64, item) => {
        try {
            const fileExt = document.name.split('.').pop();
            const fileName = document.name.slice(0, document.name.lastIndexOf('.')).replace(/\./g, '');
            const channelName = shortify(slugify(fileName), 10)
            const path = slugify(item.Type)
            const slug = `${path}/${channelName}.${fileExt}`;
            const channel = peerConnection.createDataChannel(`${slug}`, channelOptions);

            channel.onerror = error => {
                // console.log('Data Channel Error:', error);
            };

            channel.onmessage = event => {
                // console.log("Got Data Channel Message:", event.data);
            };

            channel.onopen = async () => {
                await sendFileBinary64(file64, channel);

            };

            if (channel._readyState === 'open') {
                await sendFileBinary64(file64, channel);
            }

            channel.onclose = () => {
                // console.log("The Data Channel is Closed");
            };
        } catch (e) {
            console.log('sendFile: ', e);
        }
    };

    return {sendFile};
}

export default useShare;
