import React from 'react';
import {Container, Grid, makeStyles, Typography} from "@material-ui/core";
import colors from "../utils/colors";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 20,
        marginLeft: -20

    },
    captcha: {
        marginBottom: 10,
    },
    heading: {
        marginTop: 20,
        marginRight: 10,
        fontSize: 30,

    },
    headingName: {
        marginRight: 10,
        fontSize: 30,
        textTransform: 'capitalize'
    },
    form: {
        marginBottom: 30,
    },
    header: {
        marginBottom: 30,
    },
    icon: {
        color: 'black'
    },
    infoPart: {
        flexDirection: 'column',
        marginLeft: -20
    },
    infoText: {
        fontWeight: 'bold'
    },
    content: {
        backgroundColor: colors.darkGray
    }
}));

function OffsiteHeader({user, header, headerDesc, headerDescStep2, step}) {
    const classes = useStyles()
    const {t, i18n} = useTranslation('translation');
    return (

        <Container>
            <Grid
                className={classes.root}
                container
                direction="row"
                alignItems="center"
            >
                <div className={classes.header}>
                    <Typography className={classes.heading} variant="h5" component="span">
                        {t(header)}
                    </Typography>
                    {user &&
                    <Typography className={classes.heading} variant="h5" component="span">
                        {user && user.IsMale === true ? (
                            t('MALE')
                        ) : (
                            t('FEMALE')
                        )}
                    </Typography>
                    }
                    <Typography
                        className={classes.headingName}
                        color={'primary'}
                        variant="h5"
                        component="span"
                    >
                         {user && user.FirstName}  {user && user.LastName}
                    </Typography>
                    {i18n.language == 'de' && step !== 2 &&
                    <Typography className={classes.heading} variant="h5" component="span">
                        {t(headerDesc)}
                    </Typography>
                    }
                    <Typography className={classes.heading} variant="h5" component="span">
                        {t(headerDescStep2)}
                    </Typography>
                </div>
            </Grid>
        </Container>
    );

}

export default OffsiteHeader;
