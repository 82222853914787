import React from 'react';
import List from '@material-ui/core/List';
import Folder from "./Folder";

export default function FoldersList({data, peerConnection, downloadProcessing}) {
    return (
        <List style={{marginTop: 40}} component="nav" aria-label="main mailbox folders">
            {data.map((folder, index) => <Folder key={index} data={folder} peerConnection={peerConnection} downloadProcessing={downloadProcessing}/>)}
        </List>
    );
}
