import React, {useEffect, useState} from 'react';
import io from 'socket.io-client';

function useSocket(url) {
    const [socket, setWS] = useState({connected: false});
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        if (!connected) {
            const _socket = io.connect(url, {path: '/io/webrtc'})
            _socket.on('connect', () => {
                // console.log('connected to signal server');
                setConnected(true);
            });

            _socket.on('disconnect', () => {
                // console.log('disconnected to signal server');
                setConnected(false);
            });

            _socket.on('connect_error', function (err) {
                // console.log('signal sever error', err);
                setConnected(false);
            });

            _socket.on('connection-success', success => {
                // console.log('Your COnnection ID is', success);
            });

            setWS(_socket);
        }
    }, []);

    return {socket, connected};
}

export default useSocket;
