import React, {useEffect, useState} from "react";
import {Button, Modal, Container, Grid, makeStyles, Typography} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer'
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CryptoJS from "crypto-js";
import axios from "axios";
import Layout from "./layout/Layout";
import Stepper from "../ui/Stepper";
import {useTranslation} from "react-i18next";
import ReportIcon from "@mui/icons-material/Report";
import useSocketOffline from "../hooks/useSocketOffline";
import usePeerOffline from "../hooks/usePeerOffline";
import api from "../utils/axios";
import { isMobile } from "react-device-detect";
import FolderZipIcon from '@mui/icons-material/FolderZip';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 30,
        paddingBottom: 20,
    },
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: 400,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        '&:focus-visible': {
            outline: 'none'
        }
    },
    folderIcon: {
        marginTop: 10,
        fontSize: 22,
    },
    folders1: {
        maxWidth: 720,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 40,
        paddingBottom: 20,
        marginBottom: 20
    },
    header: {
        marginTop: 20,
        marginBottom: 30,
        display: "flex",
        flexDirection: "row",
    },
    topView: {
        marginTop: '1px',
        backgroundColor: "#eeeeeee"
    },
    offStep1: {
        width: '30%',
        marginTop: 50,
        display: "flex",
        flexDirection: "column"
    },
    request_auth: {
        fontSize: 18
    },
    authorisationButton: {
        marginTop: 20,
        width: '60%',
        textTransform: 'capitalize'
    },
    offStep2: {
        marginTop: 50,
        display: "flex",
        flexDirection: "row",
        marginBottom: 50
    },
    requestText: {
        width: '50%',
        fontSize: 18
    },
    clockImg: {
        marginLeft: 300,
        marginTop: 20
    },
    paper2: {
        marginLeft:30,
        // padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        flex: 1
    },
    paper1: {
        minHeight: 130,
        width: 200,
        backgroundColor: "white",
        borderRadius: 2,
        cursor: "pointer",
        paddingTop: 20,
        boxShadow: "2px 2px #bdbdbd",
    },
    folderRound: {
        height: 40,
        width: 40,
        borderRadius: 25,
        backgroundColor: "#eeeeee",
        marginLeft: "40%",
    },
    folderIcon: {
        marginTop: 10,
        fontSize: 22,
    },
    header1: {
        display: "flex",
        flexDirection: "column",
    },
    Report1: {
        marginRight: 10,
        marginTop: 10,
    },
    Report2: {
        fontWeight: 'bold'
    },
    download: {
        marginTop: 40,
        marginLeft:30,
        textTransform: "none"
    },
    folderText: {
        fontSize: 12,
        marginTop: 10,
        textAlign: "center",
        color: "black",
        overflow: 'hidden',
        wordBreak: "break-word"
        
    },
    closeConnection: {
        marginTop: 20,
        marginRight: 30
    },
    reportButton: {
        padding: 10,
        backgroundColor: "black",
        color: "white",
        fontWeight: "bold",
        height: 30,
        textTransform: "capitalize",
        marginLeft: 30,
        marginTop: -20,
    },
    paragraphContent: {
        backgroundColor: 'white',
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 15,
        paddingBottom: 1
    },
    alertTitleContent: {
        display: 'flex',
    },
    alertTitle: {
        alignSelf: 'center',
        fontSize: 20,
        paddingLeft: 10,
        fontWeight: 'bold'
    },
    buttonContent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        flexDirection: 'row',
        paddingBottom: 20,
        paddingLeft: 25,
        paddingRight: 20,
    },
    buttonStyle: {
        color: '#09cdb7',
        outlineColor: '#09cdb7'
    },
    countdownTextPadding: {
        paddingTop: 10,
        fontWeight: 'bold'
    }
}));

function Offsite({match}) {
    let history = useHistory();
    const classes = useStyles();
    const [loader, setLoader] = useState(false);
    const [isOpenModal, setOpenModal] = useState(false);
    //Decrypt Key
    const [key, setKey] = useState('');
    const {t} = useTranslation("translation");
    const stepsOff = [1, 2, 3];
    const [stepOff, setStepOff] = useState(1);
    const [user, setUser] = useState({});
    const [startShare, setStartShare] = useState(false);
    const [sessionId, setSessionId] = useState(match?.params?.id);
    const [header, setHeader] = useState("");
    const [headerDesc, setHeaderDesc] = useState("");
    const [headerDescStep2, setHeaderDescStep2] = useState("");
    const [step, setStep] = useState(2);
    const {socket, connected} = useSocketOffline(sessionId);
    const currentDate = new Date();
    const [countdown, setCountdown] = useState(30);
    const timeout = 60000
    const [isIdle, setIsIdle] = useState(false)
    const [openAlert, setOpenAlert] = useState(false);
    const [modalInterval, setModalInterval] = useState(null);
    const [startCountdownInterval, setStartCountdownInterval] = useState(false);
    const handleOnActive = () => setIsIdle(false)
    const handleOnIdle = () => setIsIdle(true)
    const {
        reset,
    } = useIdleTimer({
        timeout,
        onActive: handleOnActive,
        onIdle: handleOnIdle,
    })

    const stepTitles = [
        {
            name: "OFFSITE_STEP_1",
            classes: "stepperTitleLeft",
        },
        {
            name: "OFFSITE_STEP_2",
            classes: "stepperTitleCenter",
        },
        {
            name: "OFFSITE_STEP_3",
            classes: "stepperTitleRight",
        },
    ];

    const {
        onlineStatus,
        peerConnection
    } = usePeerOffline(
        startShare,
        connected,
        socket,
        sessionId
    );

    const handleReceiveMessage = (event) => {
        // console.log('event.currentTarget.label  ', event.currentTarget.label )
        if (event.currentTarget.label === "shareKey") {
            console.log(event.data);
            setKey(event.data)
            setStepOff(3)
        } 
    }
    const handleReceiveChannelStatusChange = (event) => {
        // console.log('send channel status: ', event)
    };

    const receiveChannelCallback = (event) => {
        const { channel } = event;
        channel.onmessage = handleReceiveMessage;
        channel.onopen = handleReceiveChannelStatusChange;
        channel.onclose = handleReceiveChannelStatusChange;
    };
    
    const SendNotification = () => {
        // Ask for Authorization
        api.get(`Offsite/SendNotificationtoUser/${sessionId}`).then((res) => {
            // console.log('SendNotificationtoUser  ', sessionId )
            if (res) {
                setStepOff(2);
            } else {
                setStepOff(1);
            }
        }).catch(err => {
            if(err?.response?.data === 'SESSION_EITHER_EXPIRE_OR_REMOVED'){
               setOpenModal(true);
            }
        })
    };

    const SendEmailforWrongPatient = () => {
        //Report Wrong Patient
        setLoader(true);
        api.post(`Offsite/SendEmailtoUserfromDoctorWrongEmailOffsite/${user.Email}`).then((res) => {
            if(res.status == 200) {
                history.push('/report');
                setLoader(false)
            }
        }).catch(err => {
            if(err?.response?.data === 'SESSION_EITHER_EXPIRE_OR_REMOVED'){
               setOpenModal(true);
            }
        })
    };

    function b64_to_utf8( str ) {
        // console.log('b64_to_utf8')
        return decodeURIComponent(escape(window.atob( str )));
    }

    const downloadFile = () => {
        // Download Zip file from server
        setLoader(true);
        //File URL comes from user data

        //File are stored as text file (.txt)
        axios.get(decodeURI(user.ShareFileURI),{
            headers: {
                'Content-Type': 'text/html; charset=UTF-8',
            }
        }).then(data => {
            const content = data.data;
            // console.log('get file data')
            // Decrypt Data
            var bytes  = CryptoJS.AES.decrypt(content, key);

            //Decode data
            var originalText = bytes.toString(CryptoJS.enc.Base64);

            // console.log('originalText')

            //Create Tag A element
            const a = document.createElement("a"); //Create <a>

            //Convert base64 to UTF8
            a.href = `data:application/zip;base64,` + b64_to_utf8(originalText); //File Base64 Goes here
            //Name file after User data
            a.download = `${t('FILE_PREFIX')}${user.FirstName}_${user.LastName}_${currentDate.getDate()}_${currentDate.getMonth()+1}_${currentDate.getFullYear()}.zip`; //File name Here
            a.hidden = true;
            a.target = "_blank";
            a.click(); //Downloaded file
            setLoader(false);
            setTimeout(() => {
                socket.emit('closeConnection', {room: sessionId}, error => {
                });
                socket.close();
                peerConnection.close();

                // console.log('close connection')

                history.push('/offsite-thank-you');
            }, 5000);
        }).catch(err => {
            if(err?.response?.data === 'SESSION_EITHER_EXPIRE_OR_REMOVED'){
               setOpenModal(true);
            }
        })
    }

    useEffect(() => {
        if (onlineStatus === "Closed" && !key) {
            history.push('/failed')
        }

        if (peerConnection) {

            // Listen to All Channels
            peerConnection.ondatachannel = receiveChannelCallback;

            socket.on('closedConnection', function () {
                peerConnection.close()
                socket.close();
                history.push('/fail');
            });
        }

    }, [peerConnection, socket, onlineStatus, connected, receiveChannelCallback, history]);

    useEffect(()=>{
        if(connected && sessionId){
            //get informed that deviced is connected
            socket.on('device_app_logged', function (data) {
                console.log('device_app_logged')
                setStartShare(true)
                // console.log('startShare ', startShare)
            });

            //inform device that web is logged
            socket.emit('webapp_login', {room: sessionId}, error => {
                // console.log('web_app_login');
            });
    
        }
    },[connected, peerConnection, startShare, sessionId, socket])

    useEffect(() => {
        if(sessionId){
            //Get user Information from sessionId
            // {
            //     name,
            //     lastname,
            //     urlFile    
            // }
            api.get(`Session/GetUserbySessionId/${sessionId}`).then((res) => {
                if (res) {
                    // console.log('res ', res);
                    localStorage.setItem('user', JSON.stringify(res.data));
                    setUser(res.data)
                }
            }).catch(err => {
                // console.log('web_app_login e', err);
                if(err?.response?.data === 'SESSION_EITHER_EXPIRE_OR_REMOVED'){
                   setOpenModal(true);
                }
            })
        }
    }, [sessionId]);

    const handleClose = (event, action) => {
        if (action && action === "backdropClick")
            return;
        window.location.reload();
    };

    const continueProcess = () => {
        setStartCountdownInterval(false);
        setCountdown(30);
        setOpenAlert(false);
        clearIntervalTime();
        reset();
    };

    const clearIntervalTime = () => {
        clearInterval(modalInterval)
    }

    const startCountdown = () => {
        if (countdown > 0 && countdown !== 0 && startCountdownInterval){
            const intervalId = setInterval(() => {
                setCountdown((time) => {
                    if (time > 1) {
                        return time - 1;
                    }
                    else{
                        window.location.reload();
                        return 0;
                    }
                });
            }, 1000);
            setModalInterval(intervalId);
        }
    }

    const cleanUp = (ev) => {
        //On Navigate or Reload Page clear the data
        ev.preventDefault();

        // console.log('clean up');
                
        socket.emit('closeConnection', {room: sessionId}, error => {
            // console.log('web_app_login e', error);
        });

        peerConnection.close();
        socket.close();
        setSessionId('');
        // setStartShare(false);
        return null
    }
    
    useEffect(() => {
        if(stepOff === 1) {
            setHeader('DOCUMENTS_HEADER_OFFSITE')
            setHeaderDesc('REQUEST')
            setHeaderDescStep2('')
            setStep(1)
        } else  if(stepOff === 2) {
            setHeader('DOCUMENTS_HEADER_OFFSITE_STEP3')
            setHeaderDesc('')
            setHeaderDescStep2('DOCUMENTS_HEADER_OFFSITE_STEP2_DESC')
            setStep(2)
        }
        else  if(stepOff === 3) {
            setHeader('DOCUMENTS_HEADER_OFFSITE_STEP2')
            setHeaderDesc('DOWNLOAD_OFFSITE')
            setHeaderDescStep2('')
            setStep(3)
        }
        window.addEventListener("beforeunload", cleanUp);

        return () => {
            window.removeEventListener('beforeunload', cleanUp);
        }
    },[stepOff])

    useEffect(() => {
        if(isIdle){
            setStartCountdownInterval(true);
            setOpenAlert(true);
            startCountdown();
        }
    }, [isIdle, startCountdownInterval])


    
    return (
        <>
            {isMobile && (
                <Layout
                    loader={loader}
                    setLoader={setLoader}
                    title={("MOBILE_NOT_SUPPORTED")}
                    fluid={false}
                >
                    <Container className={classes.topView}></Container>
                </Layout>
            )}

            {!isMobile && (
                <Layout loader={loader} setLoader={setLoader} title={'offsite'} header={header} headerDesc={headerDesc} headerDescStep2={headerDescStep2} step={step} user={user} fluid={false}>
                    <Modal
                        className={classes.modal}
                        open={isOpenModal}
                        onClose={setOpenModal}
                        aria-labelledby="session-expire-title"
                        aria-describedby="session-expire-description"
                    >
                        <div className={classes.paper}>
                            <h2 id="server-modal-title">{t('SESSION_TITLE')}</h2>
                            <p id="server-modal-description">{t('SESSION_EITHER_EXPIRE_OR_REMOVED')}</p>
                            <Button
                                onClick={()=>{
                                    history.push('/');
                                }}
                                className={classes.authorisationButton}
                                variant="contained"
                                color="primary"
                            >
                                {t("SESSION_CLOSE_WINDOWS")}
                            </Button>
                        </div>
                            
                    </Modal>
                    <Dialog
                        open={openAlert}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        PaperProps={{ style: { borderRadius: 0 }}}>
                        <DialogTitle id="alert-dialog-title" className={classes.alertTitleContent}>
                            <Typography><img src="/clock-filled.png" width={50} height={50} alt="Clock"/></Typography>
                            <Typography className={classes.alertTitle}>{t("TIMEOUT")}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Typography id="alert-dialog-description1">
                                {t('YOU_HAVE_BEEN_IDLE')}
                            </Typography>
                            <Typography id="alert-dialog-description2">
                                {t('YOUR_SESSION_WILL_BE_RESET_1')}{' '}{t('YOUR_SESSION_WILL_BE_RESET_2')}
                            </Typography>
                            <Typography className={classes.countdownTextPadding}>
                                {t('COUNTDOWN')}: {countdown}
                            </Typography>
                        </DialogContent>
                        <div className={classes.buttonContent}>
                            <Button
                                onClick={() => continueProcess()}
                                variant="contained"
                                color="primary"
                            >
                                {t('CONTINUE')}
                            </Button>
                            <Button
                                onClick={() => handleClose()}
                                variant="outlined"
                                color="primary"
                                classes={{ label: classes.buttonStyle }}
                            >
                                {t('RESET')}
                            </Button>
                        </div>
                    </Dialog>
                    <Container className={classes.topView}>
                        <Stepper
                            step={stepOff}
                            steps={stepsOff}
                            title={"CLAIM_AUTHORIZATION"}
                            titles={stepTitles}
                            extendedtitle={"DOWNLOAD_DOC"}
                            thirdtitle={"WAIT_RESPONSE"}
                        />
                        {stepOff === 1 &&
                        <>
                            <Grid
                                className={classes.root}
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <div className={classes.offStep1}>
                                    <Typography className={classes.request_auth}>
                                        {t("OFFSITE_STEP1")}
                                    </Typography>

                                    <Button
                                        onClick={SendNotification}
                                        className={classes.authorisationButton}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {t("REQUEST_AUTH")}
                                    </Button>
                                </div>
                            </Grid>
                            <Grid container className={classes.folders1}>
                                <div className={classes.header}>
                                    <ReportIcon className={classes.Report1}></ReportIcon>
                                    <div className={classes.header1}>
                                        <Typography
                                            className={classes.Report2}>{t("PATIENT_NOT_KNOWN")}</Typography>
                                        <Typography>{t("REPORT_US")}</Typography>
                                    </div>
                                </div>
                                <Button className={classes.reportButton}  onClick={SendEmailforWrongPatient} size="small">
                                    {t("REPORT")}
                                </Button>
                            </Grid>
                        </>
                        }
                        {stepOff === 2 &&
                        <Grid
                            className={classes.root}
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <div className={classes.offStep2}>
                                <div className={classes.requestText}>
                                    <Typography paragraph style={{paddingBottom: 10}}>
                                        <strong>{t("OFFSITE_STEP2_PAR1_1")}</strong> <br/> <u>{t("OFFSITE_STEP2_PAR1_2")}</u>
                                    </Typography>
                                    <div className={classes.paragraphContent}>
                                        <Typography paragraph>
                                            <b>{t("OFFSITE_STEP2_PAR2_1")}</b> <br/> {t("OFFSITE_STEP2_PAR2_2")} <u>{user.PhoneNumber}</u>
                                        </Typography>                         
                                    </div>
                                </div>
                                <img src="/clock.png" width={150} height={150} className={classes.clockImg} alt="Clock"/>
                            </div>
                        </Grid>
                        }
                        {stepOff === 3 &&
                            <Grid
                                className={classes.root}
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <div className={classes.offStep2}>
                                {!!key &&
                                    <div className={classes.requestText}>
                                        {/* <Button variant="contained" color={'primary'}
                                                    className={classes.closeConnection}
                                                    onClick={() => {
                                                        socket.emit('closeConnection', {room: sessionId}, error => {
                                                           
                                                        });
                                                        socket.close();
                                                        peerConnection.close();

                                                        history.push('/offsite-thank-you');
                                                    }}>{t('CONFIRM_CLOSE')}</Button> */}

                            <div className={classes.paper2}>
                                <div
                                    className={classes.paper1}
                                  
                                >
                                    <div className={classes.folderRound}>
                                        <FolderZipIcon className={classes.folderIcon}/>
                                    </div>
                                    <Typography className={classes.folderText}>
                                    {t('FILE_PREFIX')}{user.FirstName} {user.LastName}_{currentDate.getDate()}_{currentDate.getMonth()+1}_{currentDate.getFullYear()}
                                    </Typography>
                                   
                                </div>
                            </div>
                        
               
                                       <Button
                                            onClick={downloadFile}
                                            className={classes.download}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {t("DOWNLOAD_DOC")}
                                        </Button>

                                    </div>
                        }
                                    <img src="/clock.png" width={150} height={150} className={classes.clockImg} alt="Clock"/>
                                </div>
                            </Grid>
                        }
                    </Container>
                </Layout>
            )}
        </>
    );
}

export default Offsite;
