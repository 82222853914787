import {Grid, makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";

const useStyles = makeStyles((theme) => ({
    root: {
        width: 720,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: "start",
        paddingTop: 30,
        paddingBottom: 30
    },
    header: {
        marginBottom: 30
    }
}));

function AGB() {
    const {t} = useTranslation('translation');
    const classes = useStyles();
    return (
        <Layout>
            <Grid
                className={classes.root}
                container
                direction="column"
                justifyContent="center"
                alignItems="center">

                <Typography variant={'h3'} className={classes.header}>AGB</Typography>
                <Typography paragraph className={classes.header}>
                    {t('AGB_PARAGRAPH')}
                </Typography>
            </Grid>
        </Layout>

    );

}

export default AGB;
