import {Grid, Container, makeStyles, Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Layout from "./layout/Layout";
import React, {useEffect, useState} from "react";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {useHistory} from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        width: 798,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        paddingTop: 30,
        paddingBottom: 20,
        wordBreak: 'break-word'
    },
    heading: {
        marginRight: 5
    },
    header: {
        marginBottom: 30
    },
    icon:{
        fontSize: 350
    }
}));

function OffsiteThankYou() {
    const {t} = useTranslation('translation');
    const classes = useStyles();
    let history = useHistory();
    const [user, setUser] = useState({})
    const [count, setCount] = useState(5)

    const cleanUp = (e) => {
        localStorage.clear();
        // e.preventDefault();
        // e.returnValue = '';
        return null;
    }

    useEffect(()=>{
        const _user = localStorage.getItem('user');
        setUser(JSON.parse(_user));
    },[setUser])

    useEffect(()=>{
        window.addEventListener('beforeunload', cleanUp);

        return () => {
            window.removeEventListener('beforeunload', cleanUp);
        }
    },[])

    useEffect(() => {
        const interval = setInterval(() => {
          console.log('This will run every second!');
          setCount(prev => prev - 1);
          if(count === 0){
            history.push('/');
          }
        }, 1000);
        return () => clearInterval(interval);
      }, [count, setCount]);

    return (
        <Layout fluid={false}>
            <Container className={classes.topView}>
                <Grid
                    className={classes.root}
                    container>

                    <div className={classes.header}>
                            <Typography className={classes.heading} variant="h5" component="span">
                                {t('HEADER_THANK_YOU_HEADER')}
                            </Typography>
                    </div>
                    <div className={classes.header}>
                         <Typography className={classes.heading} variant="body1" paragraph={true} component="span">
                            {count}
                        </Typography>
                        <Typography className={classes.heading} variant="body1" paragraph={true} component="span">
                            {t('HEADER_THANK_YOU_DESCRIPTION')}
                        </Typography>
                        <br/>
                        <CheckCircleIcon className={classes.icon}  color={'primary'} fontSize={'large'}/>
                    </div>
                </Grid>
            </Container>
        </Layout>
    );
}

export default OffsiteThankYou;
