import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import useIcon from "../../hooks/useIcon";
import {makeStyles} from "@material-ui/core";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Delete from '@mui/icons-material/Delete';
import {has} from "../../utils/isArray";
import {Pagination} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),

    },
    listItem: {
        backgroundColor: "white",
        boxShadow: "2px 2px #bdbdbd",
        borderRadius: 3,
        padding: 10,
        marginBottom: 20,
        maxWidth: 800,
        minWidth: 800,
        maxHeight: 40,
        minHeight: 40
    },
    pagination: {
        margin: 20,
        width: '100%',
        display: 'flex',
        alignItems: "flex-end",
        flexDirection: "row-reverse"
    }
}));

export default function FilesList({data, peerConnection, deleteFlag = false, removeFile, downloadProcessing}) {
    const channelOptions = {
        ordered: true,
        protocol: 'tcp',
    };
    const {returnIconByType} = useIcon();
    const classes = useStyles();
    const [page, setPage] = useState(1)
    const [files, setFiles] = useState([])

    const totalPages = () => {
        return Math.ceil(data.length / 10);
    }

    const pagination = (data, page = 1, limit = 10) => {
        let start = (page - 1) * limit;
        let end = start + limit;
        let _data = [...data];
        let max = data.length;
        if( end > max){
            end = max;
        }
        let _items = _data.slice(start, end);
        setFiles(_items)
    };

    const handleChange = (event, value) => {
        setPage(value);
    };

    const downloadFile = (file) => {
        downloadProcessing();
        const fileChannel = peerConnection.createDataChannel('shareFile', channelOptions);

        fileChannel.onerror = (error) => {
            // console.log("Data Channel Error:", error);
        };

        fileChannel.onmessage = (event) => {
            // console.log("Got Data Channel Message:", event.data);
        };

        fileChannel.onopen = () => {
            // console.log('request file to be download ', file)
            fileChannel.send(JSON.stringify(file));
        };

        fileChannel.onclose = () => {
            // console.log("The Data Channel is Closed");
        };
    }

    useEffect(() => {
        if (data.length > 10) {
            pagination(data, page)
        } else {
            setFiles(data);
        }
    }, [data, page])

    return (
        <>
            <List>
                {has(files) && files.map((file, index) => {
                        const {icon} = returnIconByType(file);
                        return <ListItem key={index} className={classes.listItem}>
                            <ListItemIcon>
                                {icon}
                            </ListItemIcon>
                            <ListItemText 
                                primary={file.name.slice(0, file.name.lastIndexOf('.')).replace(/\./g, '') + '.' + file.name.split('.').pop()} 
                                style={{wordBreak: 'break-all'}}/>
                            {!deleteFlag && <ListItemIcon button="true" onClick={() => downloadFile(file)} style={{cursor: 'pointer'}}>
                                <FileDownloadOutlinedIcon style={{color: 'gray', marginLeft: 50, fontSize: 25,}}/>
                            </ListItemIcon> }
                            {deleteFlag && <ListItemIcon button="true" onClick={() => removeFile(file)} style={{cursor: 'pointer'}}>
                                <Delete style={{color: 'red', marginLeft: 50, fontSize: 25,}}/>
                            </ListItemIcon>}
                            
                        </ListItem>
                    }
                )}
            </List>
            {totalPages() > 1 &&
            <Box className={classes.pagination}>
                <Pagination page={page} onChange={handleChange} count={totalPages()} shape="rounded"/>
            </Box>
            }

        </>

    );
}
